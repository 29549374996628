import React from "react"

import {
  Title,
  Subtitle,
  Section,
  Container,
  Columns,
  Column,
  Button,
} from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BuildingBlocks from "../assets/bulding-blocks.svg"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Coming Soon" />
    <Section>
      <Container>
        <Columns>
          <Column isSize="1/3">
            <Title>Coming Soon</Title>
            <Link to="/">
              <Button isSize="large" isColor="primary">
                Home
              </Button>
            </Link>
          </Column>
          <Column isSize="1/2">
            <BuildingBlocks width="vw" height="400px" />
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
